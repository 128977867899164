<template>
  <b-container>
    <b-form v-on:submit.prevent>
      <b-row>
        <b-col cols="8">
          <b-card>
            <label for="items" class="text-primary font-weight-bolder mb-1"
              >Detail</label
            >
            <b-row>
              <b-col>
                <b-form-group label="Kode Promosi" label-for="code">
                  <b-form-input
                    id="code"
                    placeholder="Kode Promosi"
                    v-model="form.code"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Tanggal Mulai" label-for="start-at">
                  <flat-pickr v-model="form.startAt" class="form-control" />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Tanggal Berakhir" label-for="end-at">
                  <flat-pickr v-model="form.endAt" class="form-control" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Alamat URL" label-for="url">
                  <b-form-input
                    id="url"
                    placeholder="Alamat URL"
                    v-model="form.url"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mt-2"
              @click="addPromotion()"
            >
              Kirim
            </b-button>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <b-form-group
              :state="errors"
              label="Image"
              label-for="Image"
              invalid-feedback="Image is required"
            >
              <validation-provider
                #default="{ errors }"
                name="Photo"
                rules="required"
              >
                <image-uploader
                  id="file"
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  :debug="0"
                  :autoRotate="true"
                  accept="image/*"
                  doNotResize="['gif', 'svg']"
                  outputFormat="string"
                  @input="setImage"
                ></image-uploader>
                <small class="text-danger">{{
                  errors[0] || errMessageImage
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import {
  BImg,
  BFormFile,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageUploader from "vue-image-upload-resize";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    quillEditor,
    ToastificationContent,
    ImageUploader,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    BContainer,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        code: "",
        startAt: null,
        endAt: null,
        url: "",
      },
      errors: "",
      errMessage: "",
      hasImage: "",
      image: "",
      photo: "",
      errMessageImage: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  updated() {
    console.log(document.getElementById("file").files[0]);
  },
  methods: {
    setImage: function (file) {
      this.hasImage = true;
      this.image = file;
    },
    addPromotion() {
      this.$http
        .post("promotion/add", {
          url: this.form.url,
          image: this.image,
          start_at: this.form.startAt,
          end_at: this.form.endAt,
          code: this.form.code,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Promosi baru berhasil dibuat",
              variant: "success",
            },
          });
          setTimeout(() => {
            location.href = "/promotions";
          }, 2000);
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
#category option:disabled {
  font-weight: bolder;
  /* color: darkgray; */
  /* text-align: center; */
  background-color: #ebebeb;
}
#category option:not([disabled]) {
  margin-left: 40px !important;
  transform: scaleX(200px);
}
img.img-preview {
  width: 100%;
  margin-bottom: 15px;
}
</style>